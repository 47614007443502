<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingAgency">
              <ValidationObserver
                v-if="agency"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateAgency)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.name"
                        name="company_name"
                        rules="required"
                        label="Company Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.email"
                        name="owner.email"
                        rules="required|email"
                        label="Email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-6">
                          <TextInput
                            v-model="agency.subdomain"
                            name="subdomain"
                            rules="required|subdomain"
                            label="Landing Page"
                            linkstart="https://reviewour.biz/p/"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscription plan</label>
                        <select
                          v-model="agency.stripe_plan"
                          class="form-control"
                          :disabled="agency.default_payment_method === null"
                        >
                          <option
                            v-for="plan in plans"
                            :key="plan.id"
                            :value="plan.id"
                            >{{ plan.name }}</option
                          >
                        </select>
                        <div
                          v-if="!agency.free_fee"
                          class="mt-2 d-flex justify-content-between"
                        >
                          <span
                            >Status:
                            <strong
                              :class="{
                                'text-primary':
                                  agency.stripe_status === 'active',
                                'text-danger':
                                  agency.stripe_status === 'canceled' ||
                                  agency.stripe_status === 'past_due',
                              }"
                              >{{ agency.stripe_status }}</strong
                            ></span
                          >
                          <div
                            v-if="agency.stripe_status !== 'canceled'"
                            class="mt-1"
                          >
                            <button
                              class="ml-1 btn btn-danger btn-sm"
                              href="javascript:void;"
                              @click.prevent="cancelSubscription"
                            >
                              Cancel subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-md-6 mb-4 mt-2">
                        <div class="mt-4 pt-2">
                          <span class="pr-2" style="font-weight:600;">OFF</span>
                          <b-form-checkbox
                            v-model="agency.free_fee"
                            name="check-button"
                            switch
                            inline
                          >
                            ON - Free fee
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>

                      <b-button
                        variant="primary"
                        :disabled="loadingImpersonate"
                        @click="impersonate"
                      >
                        <b-spinner v-if="loadingImpersonate" small />
                        <span v-else
                          >Log in <i class="uil uil-sign-out-alt"></i
                        ></span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="passwordForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(changePassword)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password"
                      name="password"
                      rules="required|confirmed:confirm_password"
                      label="Password"
                      type="password"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password_confirmation"
                      vid="confirm_password"
                      name="confirm_password"
                      rules="required"
                      label="Confirm Password"
                      type="password"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingPassword || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingAgency: false,
      agency: null,
      loadingUpdate: false,
      subscribed: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      loadingImpersonate: false,
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getAgency()
  },

  methods: {
    updateAgency() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/update', this.agency)
        .then((agency) => {
          this.agency = agency
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getAgency() {
      this.loadingAgency = true

      this.$store
        .dispatch('agency/find', this.$route.params.id)
        .then((agency) => {
          this.subscribed = agency.stripe_plan !== null
          this.agency = agency
          this.loadingAgency = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.index' })
          this.loadingAgency = false
        })
    },

    cancelSubscription() {
      this.loadingUpdate = true

      this.$store
        .dispatch('subscription/cancel', this.agency.owner.id)
        .then((agency) => {
          this.agency.stripe_plan = null
          this.agency.stripe_status = 'canceled'
          this.subscribed = false
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.agency.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true
      const user = this.agency.owner
      if (user) {
        this.$store
          .dispatch('auth/impersonate', user.id)
          .then((res) => {
            this.$store
              .dispatch('auth/getProfile')
              .then((user) => {
                this.$router.push('/' + user.role.toLocaleLowerCase())
                this.loadingImpersonate = false
              })
              .catch(() => {
                this.loadingImpersonate = false
              })
          })
          .catch(() => {
            this.loadingImpersonate = false
          })
      }
    },
  },
}
</script>
